<template>
    <div id="container"></div>
</template>

<script>
import InfoStore from "../store/InfoStore";
import vec2 from "gl-vec2";

import vertShader from "../assets/glsl/backgroundShapes.vert";
import fragShader from "../assets/glsl/backgroundShapes.frag";

const PROFILING_ENABLED = 0;
const DEBUG_SIZES = 1;
const DEBUG_SHAPES = 0;

function vec2ToFixedString(v) {
    if(v == null) {
        return "null";
    }
    return v[0].toFixed(3) + ", " + v[1].toFixed(3);
}

// same calculation as done for domain var p in frag shader
// p = ( 2.0 * p - res ) / res.y;
function screenToSDF(p, res) {
    vec2.mul(p, p, vec2.fromValues(2.0, 2.0));
    vec2.subtract(p, p, res);
    vec2.divide(p, p, vec2.fromValues(res[1], res[1]));
}

export default {
    name: "BackgroundRectGL",
    data() {
        return {
            resolution: null,
            isTouching: false,
            touchPos: null,
            touchDownPos: null,
            touchAnim: 0, // value from 0:1 that the shader uses to animate a touch
            interactiveAnim: 0,
            closestShapeStartPos: null,
            shapePositions: [], // stored in viewport %
            currentShapeIndex: -1,
            regl: null,
        };
    },
    // lifecycle hooks:
    mounted() {
        InfoStore.clear(); // TODO: make sure this isn't messing up all info displaying
        this.initRegl();
        this.initShapePositions();

        console.log("adding listeners");
        window.addEventListener("resize", this.onResize);

        // TODO: should I attach to container or window?
        window.addEventListener("mousedown", this.onMouseDown);
        window.addEventListener("mousemove", this.onMouseMove);
        window.addEventListener("mouseup", this.onMouseUp);

        window.addEventListener("touchstart", this.onTouchStart);
        window.addEventListener("touchmove", this.onTouchMove);
        window.addEventListener("touchend", this.onTouchEnd);
        window.addEventListener("touchcancel", this.onTouchCancel);
    },

    beforeDestroy() {
        console.log("removing listeners");
        window.removeEventListener("resize", this.onResize);

        window.removeEventListener("mousedown", this.onMouseDown);
        window.removeEventListener("mousemove", this.onMouseMove);
        window.removeEventListener("mouseup", this.onMouseUp);

        window.removeEventListener("touchstart", this.onTouchStart);
        window.removeEventListener("touchmove", this.onTouchMove);
        window.removeEventListener("touchend", this.onTouchEnd);
        window.removeEventListener("touchcancel", this.onTouchCancel);
    },
    methods: {
        initRegl: function() {
            let container = document.getElementById("container");

            let extensions = [];
            if(PROFILING_ENABLED) {
                extensions.push('EXT_disjoint_timer_query');
            }

            this.regl = require("regl")({
                container: container,
                // pixelRation: 1.0, // disables 2x res on mobile
                optionalExtensions: extensions
            });

            if(PROFILING_ENABLED) {
                const hasTimerQuery = this.regl.hasExtension('EXT_disjoint_timer_query');
                console.log( "has timerQuery: " + hasTimerQuery);
                InfoStore.set(0, "hasTimerQuery", hasTimerQuery );
            }

            this.setResolution(container.clientWidth, container.clientHeight);

            console.log("(init) container resolution: " + this.resolution);

            const draw = this.regl({
                profile: PROFILING_ENABLED,
                vert: vertShader,
                frag: fragShader,
                attributes: {
                    position: [-1, -1, 1, -1, 1, 1, 1, 1, -1, 1, -1, -1]
                },
                uniforms: {
                    uTime: ({ time }) => time,
                    uResolution: () => this.resolution,
                    uTouchAnim: () => this.touchAnim,
                    uInteractiveAnim: () => this.interactiveAnim,
                    uCurrentShapeIndex: () => this.currentShapeIndex,
                    // not currently used, but might to animate the background
                    // uTouchPos: () => { return [this.touchPos[0], this.touchPos[1], this.isTouching ? 1 : -1]; },
                    "uShapePositions[0]": () => this.shapePositions[0],
                    "uShapePositions[1]": () => this.shapePositions[1],
                    "uShapePositions[2]": () => this.shapePositions[2]
                },
                depth: {
                    enable: false
                },
                count: 6
            });

            // TODO: ask on chat why stats.gpuTime / cpuTime are cumalative;
            let cpuTimeAccum = 0.0;
            let gpuTimeAccum = 0.0;

            const loop = this.regl.frame( (context) => {
                try {
                    if(DEBUG_SIZES) {
                        this.debugShowResolutions(context);
                    }
                    else if(DEBUG_SHAPES) {
                        this.debugShowShapePositions();
                    }
                    else {
                        InfoStore.set(0, "time", context.time.toFixed(4));                    
                    }

                    // increment / decrement anim vars
                    if(this.isTouching) {
                        this.touchAnim = Math.min( 1.0, this.touchAnim + 5.0 / 60.0);
                    }
                    else {
                        this.touchAnim = Math.max( 0.0, this.touchAnim - 2.0 / 60.0);
                    }
                    if(InfoStore.backgroundIsInteractive) {
                        this.interactiveAnim = Math.min( 1.0, this.interactiveAnim + 5.0 / 60.0);
                    }
                    else {
                        this.interactiveAnim = Math.max( 0.0, this.interactiveAnim - 2.0 / 60.0);
                    }

                    this.regl.clear({ color: [0, 0, 0, 1] });
                    draw();

                    if(PROFILING_ENABLED) {
                        const cpuThisFrame = draw.stats.cpuTime - cpuTimeAccum;
                        cpuTimeAccum = draw.stats.cpuTime;
                        InfoStore.set(1, "cpu", cpuThisFrame.toFixed(4));
                        const gpuThisFrame = draw.stats.gpuTime - gpuTimeAccum;
                        gpuTimeAccum = draw.stats.gpuTime;
                        InfoStore.set(2, "gpu", gpuThisFrame.toFixed(4));
                    }
                } catch (err) {
                    loop.cancel();
                    throw err;
                }
            });
        },

        uninit: function() {},
        onResize: function() {
            let container = document.getElementById("container");
            this.setResolution(container.clientWidth, container.clientHeight);
            //console.log("(onResize) container resolution: " + this.resolution);

            // TODO: remove this and let shape positions persist, once they are defined in some relative scale
            this.initShapePositions();
        },
        onMouseDown: function(event) {
            if(!InfoStore.backgroundIsInteractive) {
                return;
            }
            this.isTouching = true;
            this.setTouchPosFromMouse(event.offsetX, event.offsetY);
            this.touchDownPos = vec2.clone(this.touchPos);
            this.pickClosestShape();
            this.moveClosestShape();

            // console.log("mousedown: " + this.touchPos);
        },
        onMouseMove: function(event) {
            if(!InfoStore.backgroundIsInteractive) {
                return;
            }
            if (this.isTouching) {
                this.setTouchPosFromMouse(event.offsetX, event.offsetY);
                this.moveClosestShape();
                // console.log( "mousemove: " + this.touchPos );
            }
        },
        onMouseUp: function(event) {
            if(!InfoStore.backgroundIsInteractive) {
                return;
            }
            this.isTouching = false;
            this.setTouchPosFromMouse(event.offsetX, event.offsetY);
            // console.log("mouseup: " + this.touchPos);
        },

        onTouchStart: function(event) {
            if(!InfoStore.backgroundIsInteractive) {
                return;
            }
            this.isTouching = true;
            this.setTouchPos(event.touches);
            this.touchDownPos = vec2.clone(this.touchPos);
            this.pickClosestShape();
            this.moveClosestShape();
            // console.log("touchstart: " + this.touchPos);
        },
        onTouchMove: function(event) {
            if(!InfoStore.backgroundIsInteractive) {
                return;
            }
            this.setTouchPos(event.touches);
            this.moveClosestShape();
            // console.log( "touchmove: " + this.touchPos );
        },
        onTouchEnd: function(event) {
            if(!InfoStore.backgroundIsInteractive) {
                return;
            }
            this.isTouching = false;
            this.setTouchPos(event.touches);
            // console.log("touchend: " + this.touchPos);
        },
        onTouchCancel: function(event) {
            if(!InfoStore.backgroundIsInteractive) {
                return;
            }
            this.isTouching = false;
            this.setTouchPos(event.touches);
            // console.log("touchcancel: " + this.touchPos);
        },
        setTouchPosFromMouse: function(x, y) {
            const pixelRatio = window.devicePixelRatio;
            this.touchPos = vec2.fromValues(
                x * pixelRatio,
                this.resolution[1] - y * pixelRatio
            );

            // this.shapePositions[this.currentShapeIndex] = this.touchPos;
            // InfoStore.set(0, "mouse down", JSON.stringify(this.isTouching));
            // InfoStore.set(1, "mouse pos", JSON.stringify(this.touchPos));
        },
        setTouchPos: function(touches) {
            if (touches.length == 0) {
                console.log("empty touches");
                return;
            }
            const touch = touches.item(0);
            const pixelRatio = window.devicePixelRatio;
            this.touchPos = vec2.fromValues(
                touch.clientX * pixelRatio,
                this.resolution[1] - touch.clientY * pixelRatio
            );

            // this.shapePositions[this.currentShapeIndex] = this.touchPos;
            // InfoStore.set(0, "touching", JSON.stringify(this.isTouching));
            // InfoStore.set(1, "pos (page)", JSON.stringify(this.touchPos));
        },
        setResolution: function(x, y) {
            const pixelRatio = window.devicePixelRatio;
            this.resolution = vec2.fromValues(x * pixelRatio, y * pixelRatio);
        },
        initShapePositions: function() {
            const pixelRatio = window.devicePixelRatio;

            this.shapePositions = [
                vec2.fromValues( -0.35,  0.2 ),
                vec2.fromValues(  0.35,  0.2 ),
                vec2.fromValues(  0.0, -0.28  )
            ];
        },
        pickClosestShape: function() {
            let p = vec2.fromValues(this.touchPos[0], this.touchPos[1]);
            screenToSDF(p, this.resolution);
            
            let closestIndex = -1;
            let closestDist = 10e6;
            const maxDist = 0.2;
            for (let i = 0; i < this.shapePositions.length; i++) {
                const s = this.shapePositions[i];
                const dist = vec2.distance(p, s);
                // console.log("[" + i + "] dist: " + dist);
                if (dist < maxDist && dist < closestDist) {
                    closestIndex = i;
                    closestDist = dist;
                    // console.log("\t- hit");
                }
            }

            if (closestIndex >= 0.0) {
                console.log("closest: " + closestIndex);
                this.currentShapeIndex = closestIndex;
                this.closestShapeStartPos = vec2.clone(this.shapePositions[this.currentShapeIndex]);
            } else {
                this.currentShapeIndex = -1;
            }
        },
        moveClosestShape: function() {
            if (this.currentShapeIndex >= 0) {
                let p = vec2.fromValues(this.touchPos[0], this.touchPos[1]);
                screenToSDF(p, this.resolution);
                let pd = vec2.fromValues(this.touchDownPos[0], this.touchDownPos[1]);
                screenToSDF(pd, this.resolution);
                let dt = vec2.subtract(vec2.create(), p, pd);

                vec2.add(this.shapePositions[this.currentShapeIndex], this.closestShapeStartPos, dt);
            }
        },
        debugShowResolutions: function(ctx) {
            let container = document.getElementById("container");

            let row = 0;            
            InfoStore.set(row++, "version", InfoStore.version );
            InfoStore.set(row++, "viewport size", [ctx.viewportWidth, ctx.viewportHeight] );
            InfoStore.set(row++, "container size", [container.clientWidth, container.clientHeight] );
            InfoStore.set(row++, "resolution", this.resolution);
            InfoStore.set(row++, "pixel ratio", window.devicePixelRatio);
            InfoStore.set(row++, "window inner size", [window.innerWidth, window.innerHeight]);
            InfoStore.set(row++, "interactive", this.interactiveAnim);

            // InfoStore.set(5, "container pos", [container.clientLeft, container.clientTop] );

            // InfoStore.set(4, "framebuffer size", [ctx.framebufferWidth, ctx.framebufferHeight] );
            // InfoStore.set(5, "draw size", [ctx.drawingBufferWidth, ctx.drawingBufferHeight] );
        },
        debugShowShapePositions: function() {
            InfoStore.set(0, "touch", vec2ToFixedString(this.touchPos));
            InfoStore.set(1, "s0", this.shapePositions[0][0].toFixed(2) + ", " + this.shapePositions[0][1].toFixed(2));
            InfoStore.set(2, "s1", this.shapePositions[1][0].toFixed(2) + ", " + this.shapePositions[1][1].toFixed(2));
            InfoStore.set(3, "s2", this.shapePositions[2][0].toFixed(2) + ", " + this.shapePositions[2][1].toFixed(2));

            InfoStore.set(4, "touching", this.isTouching);
            InfoStore.set(5, "shape index", this.currentShapeIndex);
            InfoStore.set(6, "touch anim", this.touchAnim);
        }
    }
};
</script>

<style scoped>
#container {
    width: 100vw;
    height: 100vh;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    background-color: rgb(0, 0, 0);
}
</style>
