import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/projects",
    name: "Projects",
    component: () => import("../views/Projects.vue")
  },
  {
    path: "/projects/kelproom",
    name: "KelpProject",
    component: () => import("../views/projects/KelpProject.vue")
  },
  {
    path: "/projects/flowparticles",
    name: "FlowParticles",
    component: () => import("../views/projects/FlowParticlesProject.vue")
  },
  {
    path: "/projects/connections-wall",
    name: "ConnectionsWallProject",
    component: () => import("../views/projects/ConnectionsWallProject.vue")
  },
  {
    path: "/projects/intel-tunnel",
    name: "IntelTunnelProject",
    component: () => import("../views/projects/IntelTunnelProject.vue")
  },
  {
    path: "/projects/cinder-audio",
    name: "CinderAudioProject",
    component: () => import("../views/projects/CinderAudioProject.vue")
  },
  {
    path: "/projects/glimpse",
    name: "GlimpseProject",
    component: () => import("../views/projects/GlimpseProject.vue")
  },
  {
    path: "/projects/centerstage",
    name: "CenterstageProject",
    component: () => import("../views/projects/CenterstageProject.vue")
  },
  {
    path: "/projects/cinder-view",
    name: "CinderViewProject",
    component: () => import("../views/projects/CinderViewProject.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
