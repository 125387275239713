<template>
  <div id="app">
    <background-gl />
    <div id="ui">
      <top-header></top-header>
      <info-overlay v-if="debug" />
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import BackgroundRectGL from "@/components/BackgroundRectGL.vue";
import TopHeader from "@/components/TopHeader.vue";
import InfoOverlay from "@/components/InfoOverlay.vue";
import InfoStore from "@/store/InfoStore";

export default {
  components: {
    "top-header": TopHeader,
    "info-overlay": InfoOverlay,
    "background-gl": BackgroundRectGL
  },
  data() {
    return {
      debug: 0
    }
  },
  beforeMount() {
    this.debug = InfoStore.debug;
    InfoStore.setDebugChangedCallback( () => {
      this.debug = InfoStore.debug;
    });
  }
};
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=PT+Serif&family=Source+Sans+Pro&display=swap');

body {
    background: #34363d;
}

#app {
  font-family: 'PT Serif', serif;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

#ui {
  // display:flex;
  // position:relative;

}

</style>
