<template>
    <div id="info">
        <!-- <p>info length: {{ infoList.length }}</p> -->
        <p class="infoItem" v-for="(info, index) in infoList" :key="info">[{{ index }}] {{ info }}</p>
    </div>
</template>

<script>
import InfoStore from "../store/InfoStore";

export default {
    name: "InfoOverlay",
    data: () => {
        return {
            infoList:[]
        }
    },
    methods: {
        onDataUpdated() {
            this.infoList = InfoStore.info.slice(0);
            // console.log("[onDataUpdated] InfoStore length: " + this.infoList.length );
        }
    },
    beforeMount() {
        InfoStore.setUpdateCallback(this.onDataUpdated);
    },
    mounted() {
        // console.log("InfoStore length: " + this.infoList.length );
    }
}
</script>

<style lang="scss" scoped>

#info {
    background:#000000A0;
    pointer-events:none;
    position:fixed;
    z-index: 100;
    // top:-260px;
}

.infoItem {
    color:#4fdfff;
    margin:2px;
    display: flex;
    // justify-content:flex-end;
    pointer-events:none;
}

</style>