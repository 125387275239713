<template>
    <a :href="url()" target="_blank">
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        viewBox="0 0 24 24"
        :aria-labelledby="iconName"
        role="presentation"
    >
        <title :id="iconName" lang="en">{{ iconName }} icon</title>
        <g :fill="iconColor">
            <path :d="svgPath()" />
        </g>
    </svg>
    </a>
</template>

<script>
import icons from '../assets/icons.json';

export default {
    name: "SvgIcon",
    props: {
        iconName: {
            type: String,
            default: "box"
        },
        width: {
            type: [Number, String],
            default: 24
        },
        height: {
            type: [Number, String],
            default: 24
        },
        iconColor: {
            type: String,
            default: "currentColor"
        }
    },
    data() {
      return {
      };
    },
    methods: {
        getIcon() {
            return icons[(this.iconName in icons) ? this.iconName : "questionMark"];
        },
        svgPath() {
            return this.getIcon().path;
        },
        url() {
            const icon = this.getIcon();
            const url = ("url" in icon ) ? icon["url"] : "";
            // console.log( "url: " + url );

            // window.location.href(url);
            return ("url" in icon ) ? icon["url"] : "";
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/common.scss";

svg {
    display: inline-block;
    vertical-align: baseline;
    margin: 0 10px;

    transition: color 0.15s ease;
    color:$light-color;
    pointer-events:auto;

    /* border:1px solid blue; */
}

svg:hover {
    color:$hover-color;
}
</style>
