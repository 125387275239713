<template>
    <div class="home">
        <!-- <div class="overlappingContent"> -->
            <h2 class="spiel">
                Creative software programmer observing Earth
            </h2>
            <div class="social">
                  <SvgIcon v-for="link in socialLinks" :key="link" :name="link"
                    :icon-name="link" width="36" height="36"
                  />
            </div>
        <!-- </div> -->
    </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon.vue";
import InfoStore from "../store/InfoStore";

export default {
    name: "Home",
    components: {
        SvgIcon
    },
    data() {
      return {
        socialLinks: ["vimeo", "github", "twitter", "linkedin", "email"]
      };
    }
    ,
    mounted() {
        InfoStore.backgroundIsInteractive = true;
        document.body.style.overflow = 'hidden';
        console.log("Home mounted");
    },
    beforeDestroy() {
        InfoStore.backgroundIsInteractive = false;
        document.body.style.overflow = 'visible';
        console.log("Home destroyed");
    }
};
</script>

<style lang="scss">

body {
    // overflow: hidden !important; // prevent scrolling (especially on mobile)
    // user-select: none !important; // prevent long-press context select on mobile (same for -webkit lines)
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: none;
    -webkit-user-select: none;
}

</style>

<style lang="scss" scoped>
@import "../assets/common.scss";

.home {
    position: relative;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    // background-color: blue;
    pointer-events:none;
}
.overlappingContent {
    position: absolute;
    top: 20px;
    pointer-events:none;
}
.spiel {
    color: $light-color;
    pointer-events:none;
    text-align: center;
    margin-top:1em;
 
}
.social {
  display: flex;
  justify-content:center;
  align-items:center;
  position: fixed;
  bottom: 1em;
  pointer-events:auto;
//   border:1px solid white;
}
</style>
