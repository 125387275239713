<template>
    <div id="nav">
        <b-navbar class="rte-nav" toggleable="md" type="dark" variant="dark">
            <b-navbar-brand class="logo" href="#" @click.native="onLogoClicked">Richard Eakin</b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">
                    <b-nav-item to="/">Home</b-nav-item>
                    <!-- <b-nav-item to="/about">About</b-nav-item> -->
                    <b-nav-item to="/projects">Projects</b-nav-item>
                    <b-nav-item href="rte_resume.pdf">Resume</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
import InfoStore from "../store/InfoStore";

export default {
    methods: {
        onLogoClicked: function(event) {
            InfoStore.toggleDebug();
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/common.scss";
@import url("https://fonts.googleapis.com/css2?family=PT+Serif&display=swap");

.logo {
    font-family: "PT Serif", serif;
}


#nav {
  a {
    font-weight: bold;
  }
}


#nav a.router-link-exact-active {
    color: white;
}

#nav a.router-link-exact-active:hover {
    color:$hover-color;
}

.navbar-dark .navbar-nav .nav-link:hover {
    color:$hover-color;
}

.rte-nav {
    background-color:#1a1d21a0 !important;
}

</style>
