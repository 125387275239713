const InfoStore = {
    debug: 0,
    version: "1.0.2",
    backgroundIsInteractive: false,
    info: [],
    updateCallback: null,
    debugChangedCallback: null,
    set: function(index, key, value) {
        this.info[index] = key + ": " + value;
        if(this.updateCallback != null) {
            this.updateCallback();
        }
    },
    clear: function() {
        this.info = [];
    },
    setUpdateCallback: function(callback) {
        this.updateCallback = callback;
    },
    setDebugChangedCallback: function(callback) {
        this.debugChangedCallback = callback;
    },
    toggleDebug() {
        this.debug = ! this.debug;
        if(this.debugChangedCallback) {
            this.debugChangedCallback();
        }
    }
};


export default InfoStore;
